import { GetStaticProps, NextPage } from 'next';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  DynamicRentalsCarousel,
  DynamicRentalsCarouselV2,
} from '@/components/common/DynamicRentalsCarousel';
import { PromosCarousel } from '@/components/home/PromosCarousel';
import { SignatureDestinations } from '@/components/home/SignatureDestinations';
import HeadMeta from '@/components/static-pages/HeadMeta';
import {
  BannerSection,
  BecomeA5StarHost,
  DiscoverTheBest,
  ListYourRV,
  NotesFromOurJournal,
  PropositionValues,
  SeoLinks,
  SocialMedia,
} from '@/graphql/components/home';
import { EListingSource } from '@/services/analytics/listings/types';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import {
  getCarouselData,
  getHomeBlogPosts,
  getSeoLinks,
  IHomeStaticProps,
  TSeoHomeSection,
} from '@/services/getHomePageData';
import { IBlogPost } from '@/services/types/blog/post.types';
import { IPromoHomepageCarousel } from '@/services/types/promos/promos';
interface Props {
  blogPosts: IBlogPost[];
  seoHomeData: TSeoHomeSection[];
  carouselData: IPromoHomepageCarousel[];
}

const HomePage: NextPage<Props> = ({ blogPosts, seoHomeData, carouselData }) => {
  const intl = useIntl();

  const transactionalAppearanceExperimentDecision = useExperimentIsEnabled(
    OptimizelyFlags.HOMEPAGE_TRANSACTIONAL_APPEARANCE,
  );

  return (
    <>
      <HeadMeta
        description={intl.formatMessage({
          defaultMessage:
            'Rent an RV, motorhome, trailer, or campervan from Outdoorsy for your next adventure. Outdoorsy is the #1 most trusted RV rental marketplace in the world',
          id: '/jiUAQ',
        })}
      />

      <div className="flex flex-col">
        <BannerSection />

        <div className="relative hidden w-full max-w-xl mx-auto bg-white xl:block shadow-[0px_-20px_20px_0px_#0000003D] rounded-t-3xl -mt-28 h-[150px]" />

        {transactionalAppearanceExperimentDecision ? (
          <>
            <div className="relative max-w-xl xl:mx-auto xl:-mt-[150px]">
              <DynamicRentalsCarouselV2 eventSource={EListingSource.HOME_CAROUSEL} />
            </div>

            <div className="bg-canvas-100">
              <SignatureDestinations />
            </div>

            <PropositionValues />
            {carouselData.length > 0 ? (
              <PromosCarousel carouselData={carouselData} />
            ) : (
              <ListYourRV />
            )}
            <DiscoverTheBest />
          </>
        ) : (
          <>
            <div className="relative z-20 xl:-mt-[150px]">
              <SignatureDestinations />
            </div>

            <div className="bg-canvas-100">
              <PropositionValues />
              {carouselData.length > 0 ? (
                <PromosCarousel carouselData={carouselData} />
              ) : (
                <ListYourRV />
              )}
              <DiscoverTheBest />
            </div>

            <DynamicRentalsCarousel eventSource={EListingSource.HOME_CAROUSEL} />
          </>
        )}

        <div className="bg-canvas-100">
          <BecomeA5StarHost />
        </div>

        <NotesFromOurJournal posts={blogPosts} />

        <div className="bg-canvas-100">
          <SocialMedia />
          <SeoLinks seoData={seoHomeData} />
        </div>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps<IHomeStaticProps> = async ({ locale }) => {
  const [seoHomeData, blogPosts] = await Promise.all([getSeoLinks(locale), getHomeBlogPosts()]);
  const carouselData = await getCarouselData();

  return {
    revalidate: 180,
    props: {
      blogPosts,
      isHomePage: true,
      seoHomeData,
      carouselData,
    },
  };
};

export default HomePage;
