import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';
import Slider, { Settings as SliderSettings } from 'react-slick';

import Divider from '@/components/switchback/Divider/Divider';
import Heading from '@/components/switchback/Heading/Heading';

import { instagramImages, pressQuotes } from './SocialMedia.data';
import css from './SocialMedia.module.css';

export const SocialMedia: React.FC = () => {
  const intl = useIntl();

  const settings: Partial<SliderSettings> = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={css.container}>
      <div className={css.images}>
        {instagramImages.map((image, key) => (
          <div key={key} className={css.image}>
            <Image src={image} role="presentation" layout="fill" objectFit="cover" />
          </div>
        ))}
      </div>
      <div className={css.article}>
        <Heading level={2} className={css.title}>
          {intl.formatMessage({
            defaultMessage: '7 million nights booked outdoors',
            id: '5Nkslp',
          })}
        </Heading>
        <div className={css.description}>
          {intl.formatMessage({
            defaultMessage:
              'Over 600,000,000 miles of adventure, countless discoveries, memories made, bonds strengthened, s’mores toasted!',
            id: 'sK3rPT',
          })}
        </div>
        <Divider className={css.divider} />
      </div>
      <div className={`${css.quotes} slider-wrapper`}>
        <Slider {...settings}>
          {pressQuotes.map((value, key) => (
            <div key={key} className={css.quote}>
              <Heading level={4}>{value.quote}</Heading>
              <span>{value.author}</span>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
