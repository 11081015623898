import {
  Button,
  Card,
  ECardBorderRadiusSize,
  ECardMediaSize,
  ETagVariant,
  ETextStyleVariant,
  Tag,
  Text,
} from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import springAcadiaNationalParkImageUrl from '@/assets/home/discover-the-best/spring-acadia-national-park.jpg';
import springGlacierNationalParkImageUrl from '@/assets/home/discover-the-best/spring-glacier-national-park.jpg';
import springGrandCanyonNationalParkImageUrl from '@/assets/home/discover-the-best/spring-grand-canyon-national-park.jpg';
import springYellowstoneNationalParkImageUrl from '@/assets/home/discover-the-best/spring-yellowstone-national-park.jpg';
import springYosemiteNationalParkImageUrl from '@/assets/home/discover-the-best/spring-yosemite-national-park.jpg';
import springZionNationalParkImageUrl from '@/assets/home/discover-the-best/spring-zion-national-park.jpg';
import Heading from '@/components/switchback/Heading/Heading';
import { formatCurrency } from '@/utility/currency';

import css from './DiscoverTheBest.module.css';

export const DiscoverTheBest: React.FC = () => {
  const intl = useIntl();

  // We are missing some of the details from the popular parks endpoint.
  // Hardcode the data for now until we will get the data from the endpoint.
  const parks = [
    {
      name: 'Yellowstone National Park',
      image: springYellowstoneNationalParkImageUrl,
      link: '/guide/yellowstone-national-park',
      state: 'Wyoming',
      price: 75,
    },
    {
      name: 'Yosemite National Park',
      image: springYosemiteNationalParkImageUrl,
      link: '/guide/yosemite-national-park',
      state: 'California',
      price: 50,
    },
    {
      name: 'Grand Canyon National Park',
      image: springGrandCanyonNationalParkImageUrl,
      link: '/guide/grand-canyon-national-park',
      state: 'Arizona',
      price: 60,
    },
    {
      name: 'Glacier National Park',
      image: springGlacierNationalParkImageUrl,
      link: '/guide/glacier-national-park',
      state: 'Montana',
      price: 60,
    },
    {
      name: 'Zion National Park',
      image: springZionNationalParkImageUrl,
      link: '/guide/zion-national-park',
      state: 'Utah',
      price: 59,
    },
    {
      name: 'Acadia National Park',
      image: springAcadiaNationalParkImageUrl,
      link: '/guide/acadia-national-park',
      state: 'Maine',
      price: 45,
    },
  ];

  return (
    <div className={css.content}>
      <Heading level={2} className={css.title}>
        {intl.formatMessage({
          defaultMessage: 'Get inspired for a family trip to national parks',
          id: 'Z2kN1L',
        })}
      </Heading>

      <div className={css.parks}>
        {parks.map(park => {
          return (
            <a key={park.link} href={park.link}>
              <Card borderRadiusSize={ECardBorderRadiusSize.Large} withBoxShadow>
                <Card.Media size={ECardMediaSize.Large} className="overflow-clip">
                  <Image src={park.image} layout="fill" objectFit="cover" objectPosition="center" />

                  <Tag
                    label={intl.formatMessage(
                      { defaultMessage: 'RVs from {price}/night', id: 'bwm57/' },
                      {
                        price: formatCurrency({
                          currency: 'USD',
                          digits: 0,
                          maxDigits: 0,
                          priceInCents: park.price * 100,
                        }),
                      },
                    )}
                    variant={ETagVariant.White}
                    className="absolute z-10 left-4 top-4"
                  />
                </Card.Media>
                <Card.Content className="px-4 pt-2 pb-4">
                  <Text variant={ETextStyleVariant.LargeBold}>{park.name}</Text>
                  <Text variant={ETextStyleVariant.SmallRegular}>{park.state}</Text>
                </Card.Content>
              </Card>
            </a>
          );
        })}
      </div>

      <div className={css.footer}>
        <a href="/guide/parks">
          <Button
            label={intl.formatMessage({
              defaultMessage: 'View all national parks',
              id: '1K5wqC',
            })}
          />
        </a>
      </div>
    </div>
  );
};
