import {
  EHeadingStyleVariant,
  EPaginationVariant,
  Heading,
  Pagination,
} from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ArrowVariants } from '@/components/switchback/Carousel/Arrow';
import Carousel from '@/components/switchback/Carousel/Carousel';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IPromoHomepageCarousel } from '@/services/types/promos/promos';

import { PromosCarouselItem } from './PromosCarouselItem';

interface Props {
  carouselData: IPromoHomepageCarousel[];
}

export const PromosCarousel = ({ carouselData }: Props) => {
  const intl = useIntl();
  const { isAboveDesktop, isAboveXL } = useBreakpoint();
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselLength = carouselData.length;

  const renderCarousel = () => {
    return (
      <Carousel
        arrows={isAboveXL}
        componentName="dynamic-promos-carousel"
        slidesToShow={1}
        slidesToScroll={1}
        hasShadow={false}
        infinite={false}
        swipeToSlide={true}
        activeSlide={activeSlide}
        arrowVariant={ArrowVariants.outside}
        afterSlideChange={page => setActiveSlide(page)}>
        {carouselData.map(item => (
          <PromosCarouselItem
            key={item.id}
            item={item.metadata}
            setActiveSlide={setActiveSlide}
            totalPages={carouselLength}
            activeSlide={activeSlide}
          />
        ))}
      </Carousel>
    );
  };

  return (
    <>
      {isAboveDesktop ? (
        <div className="w-full px-10 pt-2 pb-20 m-auto max-w-[calc(66rem+5px)]">
          {carouselLength > 1 && (
            <div className="m-0 mb-6 text-center md:mb-10">
              <Heading
                variant={EHeadingStyleVariant.H4}
                className="flex items-center justify-center">
                <FormattedMessage
                  defaultMessage="Rent an RV for the total solar eclipse"
                  id="RQtaVl"
                />
              </Heading>
            </div>
          )}

          <div>{renderCarousel()}</div>

          {carouselLength > 1 && (
            <Pagination
              ariaLabel={intl.formatMessage({ defaultMessage: 'pagination', id: 't3T8j5' })}
              onChange={(page: number) => {
                setActiveSlide(page - 1);
              }}
              totalPages={carouselLength}
              variant={EPaginationVariant.Dots}
              pageNumber={activeSlide + 1}
              className="flex justify-center"
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center px-6 pt-2 pb-20">
          {carouselLength > 1 && (
            <div className="text-center pb-7">
              <Heading variant={EHeadingStyleVariant.H4}>
                <FormattedMessage
                  defaultMessage="Rent an RV for the total solar eclipse"
                  id="RQtaVl"
                />
              </Heading>
            </div>
          )}

          <div className="w-full">{renderCarousel()}</div>
        </div>
      )}
    </>
  );
};
