import { Button, Divider, EButtonColorVariant, EDividerVariant } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouter } from '@/hooks/useRouter';
import { yieldToMain } from '@/utility/yieldToMain';

import { useApplySearch } from './applySearch';
import { DateFilterButton } from './DateFilterButton';
import { DateFilterContent } from './DateFilterContent';
import { GuestsFilterButton } from './GuestsFilterButton';
import { GuestsFilterContent } from './GuestsFilterContent';
import { LocationAutocompleteProvider } from './LocationAutocompleteContext';
import { LocationAutocompleteMenu } from './LocationAutocompleteMenu';
import { LocationFilterProvider } from './LocationFilterContext';
import { LocationFilterInput } from './LocationFilterInput';
import { VehicleTypeFilterButton } from './VehicleTypeFilterButton';
import { VehicleTypeFilterContent } from './VehicleTypeFilterContent';

enum EHomeSearchWidgetFilter {
  Location = 'Location',
  Date = 'Date',
  Guests = 'Guests',
  VehicleType = 'Vehicle Type',
}

export const DesktopHomeSearchWidget = () => {
  const intl = useIntl();
  const router = useRouter();

  const containerRef = useRef<HTMLFormElement>(null);

  const [openedFilter, setOpenedFilter] = useState<EHomeSearchWidgetFilter>();

  const setOpenedFilterYTM = (filter?: EHomeSearchWidgetFilter) => {
    yieldToMain(() => setOpenedFilter(filter));
  };

  const toggleTargetFilter = (targetFilter: EHomeSearchWidgetFilter) => {
    return () => setOpenedFilterYTM(openedFilter === targetFilter ? undefined : targetFilter);
  };

  const [, { update: updatePrevBaseUrl }] = useLocalStorage('prevBaseUrl');
  const applySearch = useApplySearch();

  const handleSearch = async () => {
    setOpenedFilter(undefined);
    updatePrevBaseUrl(router.route);
    await applySearch();
  };

  useEffect(() => {
    const handlePressEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenedFilterYTM(undefined);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpenedFilterYTM(undefined);
      }
    };

    document.addEventListener('keydown', handlePressEscape, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handlePressEscape, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="w-full max-w-lg">
      <form
        ref={containerRef}
        onSubmit={async e => {
          e.preventDefault();
          await handleSearch();
        }}
        className={cn('relative flex items-center bg-white rounded-[38px]', {
          '!bg-gray-100 rounded-b-none': openedFilter,
        })}>
        <div
          className={cn('flex-grow pr-2 pl-4', {
            'bg-white rounded-l-[inherit]': openedFilter === EHomeSearchWidgetFilter.Location,
          })}>
          <LocationFilterProvider onNavigateToRecentSearch={() => setOpenedFilter(undefined)}>
            <LocationAutocompleteProvider
              isOpened={openedFilter === EHomeSearchWidgetFilter.Location}
              onOpen={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
              onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}>
              <LocationFilterInput />
              <LocationAutocompleteMenu />
            </LocationAutocompleteProvider>
          </LocationFilterProvider>
        </div>

        <Divider height="44px" variant={EDividerVariant.Vertical} />

        <div className="flex items-center">
          <div
            className={cn('w-[165px] px-2', {
              'bg-white': openedFilter === EHomeSearchWidgetFilter.Date,
            })}>
            <DateFilterButton toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Date)} />

            <DateFilterContent
              onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
              onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
              onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
              isOpened={openedFilter === EHomeSearchWidgetFilter.Date}
              onClose={() => setOpenedFilterYTM(undefined)}
            />
          </div>

          <Divider height="44px" variant={EDividerVariant.Vertical} />

          <div
            className={cn('w-[150px] px-2', {
              'bg-white': openedFilter === EHomeSearchWidgetFilter.Guests,
            })}>
            <GuestsFilterButton toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Guests)} />

            <GuestsFilterContent
              onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
              onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.VehicleType)}
              isOpened={openedFilter === EHomeSearchWidgetFilter.Guests}
              onClose={() => setOpenedFilterYTM(undefined)}
            />
          </div>
        </div>

        <Divider height="44px" variant={EDividerVariant.Vertical} />

        <div
          className={cn('flex items-center w-[350px] gap-4 pl-2 pr-4', {
            'bg-white rounded-r-[inherit]': openedFilter === EHomeSearchWidgetFilter.VehicleType,
          })}>
          <VehicleTypeFilterButton
            toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.VehicleType)}
          />

          <VehicleTypeFilterContent
            onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
            onSearch={handleSearch}
            isOpened={openedFilter === EHomeSearchWidgetFilter.VehicleType}
            onClose={() => setOpenedFilterYTM(undefined)}
          />

          <Button
            type="submit"
            label={intl.formatMessage({
              defaultMessage: 'Search',
              id: 'D5G/Wt',
              description: 'Home Search Widget > Search Button Label',
            })}
            variant={EButtonColorVariant.Primary}
          />
        </div>
      </form>
    </div>
  );
};
