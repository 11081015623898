import he from 'he';
import Head from 'next/head';
import React from 'react';
import { useIntl } from 'react-intl';

import useDefaultHomePageAndMetaValues from '@/hooks/useDefaultHomePageAndMetaValues';

interface IHeadMetaProps {
  title?: string;
  description?: string;
  url?: string;
  canonicalUrl?: string;
  noindex?: boolean;
  nofollow?: boolean;
  thumbnailURL?: string;
}

const HeadMeta: React.FC<IHeadMetaProps> = ({
  title: preferredTitle,
  description: preferredDescription,
  url,
  canonicalUrl,
  noindex,
  nofollow,
  children,
  thumbnailURL: preferredThumbnail,
}) => {
  const intl = useIntl();
  const defaultDescription = intl.formatMessage({
    defaultMessage:
      'Find RV Rentals Near you on Outdoorsy. The only person-to-person RV rental marketplace in the US',
    id: 'qz1auY',
  });
  const { defaultTitle, defaultThumbnail } = useDefaultHomePageAndMetaValues();

  const title = preferredTitle || defaultTitle;
  const description = preferredDescription || defaultDescription;
  const thumbnailURL = preferredThumbnail || defaultThumbnail;

  let robotsContent = 'max-image-preview:large';
  if (noindex && nofollow) {
    robotsContent = 'none';
  } else if (noindex) {
    robotsContent = 'noindex';
  }

  return (
    <Head>
      <title>{title ? `${he.decode(title)} | Outdoorsy` : 'Outdoorsy'}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta property="fb:app_id" content="604465049688341" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:site_name" content="Outdoorsy" />
      <meta property="og:image" content={thumbnailURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@outdoorsy" />
      <meta name="twitter:creator" content="@outdoorsy" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={thumbnailURL} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta name="robots" content={robotsContent} />
      {children}
    </Head>
  );
};

export default HeadMeta;
