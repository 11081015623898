import { ELinkVariant, ETextStyleVariant, Icon, Link, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { HomeGuidedSearch } from './HomeGuidedSearch';

export const HomeGuidedSearchB = () => {
  const intl = useIntl();

  return (
    <HomeGuidedSearch
      TriggerComponent={({ showGuidedSearch }) => {
        return (
          <div className="w-full max-w-md lg:max-w-lg">
            <button
              onClick={showGuidedSearch}
              className="flex items-center justify-between w-full p-1 pl-4 bg-white border rounded-full lg:p-4 lg:pl-8 gap-4 border-neutral-20 lg:border-none">
              <Text variant={ETextStyleVariant.LargeRegular} component="span" className="truncate">
                <span className="hidden lg:block">
                  {intl.formatMessage({
                    defaultMessage: 'Need help finding the perfect rig? Try our guided search',
                    id: 'btr94F',
                  })}
                </span>

                <span className="lg:hidden">
                  {intl.formatMessage({
                    defaultMessage: 'Try our guided search',
                    id: 'She5wi',
                  })}
                </span>
              </Text>

              <span className="flex items-center justify-center w-12 h-12 text-white rounded-full bg-bark-30 shrink-0">
                <Icon name="General.Search" width={24} height={24} />
              </span>
            </button>

            <div className="justify-center hidden w-full lg:flex">
              <div className="px-6 py-2 rounded-b-lg bg-primary-800">
                <Link
                  onDark
                  href="/rv-search"
                  variant={ELinkVariant.Underline}
                  textVariant={ETextStyleVariant.MediumBold}
                  label={intl.formatMessage({
                    defaultMessage: 'Search all RV listings now',
                    id: 'oLf2Wy',
                  })}
                  className="flex gap-1">
                  <Icon name="General.Arrow.Right" width={24} height={24} />
                </Link>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
