import { createSelector } from 'reselect';

import { initialState } from '@/redux/reducers/rentalsReducer';
import { TRootState } from '@/redux/rootReducer';

type TRentalsFilters = TRootState['rentals']['filters'];

type TCurrentFilters = TRootState['rentals']['currentFilters'];

export const getRentalsMeta = (state: TRootState) => state.rentals.meta;

const getRentalsFilters = (state: TRootState) => state.rentals.filters;

export const getCurrentFilters = (state: TRootState) => state.rentals.currentFilters;

const getIsRentalsFiltersEmpty = createSelector<TRootState, TRentalsFilters, boolean>(
  getRentalsFilters,
  filters => {
    return Object.keys(filters).every(filter => {
      const filterValue = filters[filter as keyof TRentalsFilters];

      if (Array.isArray(filterValue)) {
        return !filterValue?.length;
      }

      if (filter === 'priceEnd') {
        return filterValue === initialState.filters.priceEnd;
      }

      return !filterValue;
    });
  },
);

const getIsCurrentFiltersEmpty = createSelector<TRootState, TCurrentFilters, boolean>(
  getCurrentFilters,
  currentFilters => {
    return Object.keys(currentFilters).every(filter => {
      const currentFilterValue = currentFilters[filter as keyof TCurrentFilters];

      if (Array.isArray(currentFilterValue)) {
        return !currentFilterValue?.length;
      }

      if (filter == 'price[max]' && Boolean(currentFilterValue)) {
        return currentFilterValue === initialState.filters.priceEnd;
      }

      return filter === 'page[limit]' || filter === 'near' || !currentFilterValue;
    });
  },
);

export const getIsRentalsFiltersAndCurrentFiltersEmpty = createSelector<
  TRootState,
  boolean,
  boolean,
  boolean
>(
  getIsRentalsFiltersEmpty,
  getIsCurrentFiltersEmpty,
  (isRentalsFiltersEmpty, IsCurrentFiltersEmpty) => isRentalsFiltersEmpty && IsCurrentFiltersEmpty,
);
