import { ELinkVariant, ETextStyleVariant, Link } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

export const MobileHomeGuidedSearchB = () => {
  const intl = useIntl();

  return (
    <div className="relative p-4 text-center bg-sand-30">
      <Link
        href="/rv-search"
        variant={ELinkVariant.Underline}
        textVariant={ETextStyleVariant.MediumBold}
        label={intl.formatMessage({
          defaultMessage: 'Search all RV listings now',
          id: 'oLf2Wy',
        })}
      />
    </div>
  );
};
