import { EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  DesktopHomeGuidedSearchA,
  HomeGuidedSearchB,
  MobileHomeGuidedSearchA,
  MobileHomeGuidedSearchB,
} from '@/components/ui/HomeGuidedSearch';
import { DesktopHomeSearchWidget, MobileHomeSearchWidget } from '@/components/ui/HomeSearchWidget';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useDefaultHomePageAndMetaValues from '@/hooks/useDefaultHomePageAndMetaValues';
import {
  EABExperimentVariationKey,
  OptimizelyFlags,
  useOptimizelyDecision,
} from '@/services/experiments';

export const BannerSection = () => {
  const { isMobile, isAboveDesktop } = useBreakpoint();

  const { homepageBanner, homepageBannerMobile } = useDefaultHomePageAndMetaValues();

  const guidedSearchOnHomeScreenDecision = useOptimizelyDecision(
    OptimizelyFlags.GUIDED_SEARCH_ON_HOME_SCREEN,
  );

  const guidedSearchOnHomeScreenAEnabled =
    guidedSearchOnHomeScreenDecision?.variationKey === EABExperimentVariationKey.ENABLED_A;

  const guidedSearchOnHomeScreenBEnabled =
    guidedSearchOnHomeScreenDecision?.variationKey === EABExperimentVariationKey.ENABLED_B;

  return (
    <>
      <div className="relative flex flex-col flex-grow">
        <div className="absolute inset-0">
          <Image
            src={isMobile ? homepageBannerMobile : homepageBanner}
            role="presentation"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority={true}
            quality={45}
          />

          <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.65)] to-[rgba(0,0,0,0)]" />
        </div>

        <div className="relative flex flex-col justify-center flex-grow px-4 py-16 md:py-24 mt-[72px] lg:px-10 gap-6 lg:gap-8 xl:mb-28">
          <div
            className="flex flex-col px-2 text-center text-white gap-2"
            data-testid="home-header">
            <Heading variant={EHeadingStyleVariant.H3}>
              <FormattedMessage
                defaultMessage="Your next Outdoorsy adventure starts here"
                id="kHLpwr"
              />
            </Heading>

            <Heading variant={EHeadingStyleVariant.H6}>
              <FormattedMessage
                defaultMessage="Discover the best RV, camper van or travel trailer rental for your next vacation."
                id="dHKjnc"
              />
            </Heading>
          </div>

          <div className="relative flex flex-col items-center">
            {(() => {
              if (guidedSearchOnHomeScreenBEnabled) return <HomeGuidedSearchB />;

              // For mobile version, show the guided search entry on the bottom of the page
              if (!isAboveDesktop) return <MobileHomeSearchWidget />;

              return (
                <>
                  <DesktopHomeSearchWidget />
                  {guidedSearchOnHomeScreenAEnabled && <DesktopHomeGuidedSearchA />}
                </>
              );
            })()}
          </div>
        </div>
      </div>

      {!isAboveDesktop && guidedSearchOnHomeScreenAEnabled && <MobileHomeGuidedSearchA />}
      {!isAboveDesktop && guidedSearchOnHomeScreenBEnabled && <MobileHomeGuidedSearchB />}
    </>
  );
};
