import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import get from 'lodash/get';
import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import ListYourRVImg from '@/assets/list-your-rv/lyrv-hero.jpg';
import Heading from '@/components/switchback/Heading/Heading';
import { CHECK_CIRCLE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { OptimizelyFlags, useOptimizelyDecision } from '@/services/experiments';
import { getLocale } from '@/utility/i18n';

import css from './ListYourRV.module.css';

export const ListYourRV: React.FC = () => {
  const locale = getLocale();
  const intl = useIntl();
  const experimentDecision = useOptimizelyDecision(OptimizelyFlags.HOMEPAGE_CUSTOM_BANNER_WEB);

  const features = [
    intl.formatMessage({
      defaultMessage: 'Rent your rig to verified drivers on Outdoorsy',
      id: 'By4gFQ',
    }),
    intl.formatMessage({ defaultMessage: 'Earn up to $50,000/year', id: 'zRmePK' }),
    intl.formatMessage({ defaultMessage: 'Get support every step of the way', id: 'uKCf9O' }),
  ];

  const bannerBullets = get(experimentDecision, 'variables.banner_bullets');

  const variables = {
    bannerTitle:
      (locale === 'en-us' && get(experimentDecision, 'variables.banner_title')) ||
      intl.formatMessage({ defaultMessage: 'Own an RV?', id: 'yIqBJV' }),
    bannerBullets: locale === 'en-us' && bannerBullets ? bannerBullets.split('\n') : features,
    bannerCtaCopy:
      (locale === 'en-us' && get(experimentDecision, 'variables.banner_cta_copy')) ||
      intl.formatMessage({ defaultMessage: 'Learn more', id: 'TdTXXf' }),
    bannerCtaUrl:
      (locale === 'en-us' && get(experimentDecision, 'variables.banner_cta_url')) ||
      '/become-a-host/rv',
    bannerImage:
      (locale === 'en-us' && get(experimentDecision, 'variables.banner_image')) || ListYourRVImg,
  };

  return (
    <div className={css.wrapper}>
      <a href={variables.bannerCtaUrl} className={css.content}>
        <div>
          <Heading level={2} className={css.title}>
            {variables.bannerTitle}
          </Heading>
          <ul>
            {variables.bannerBullets.map((feature: string, key: number) => (
              <li key={key}>
                <Icon name={CHECK_CIRCLE} size={IconSize.normal} />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
          <Button variant={EButtonColorVariant.Primary} label={variables.bannerCtaCopy} />
        </div>
        <figure>
          <Image
            src={variables.bannerImage}
            role="presentation"
            layout="fill"
            objectFit="cover"
            objectPosition="bottom"
          />
        </figure>
      </a>
    </div>
  );
};
