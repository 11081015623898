import { ELinkVariant, ETextStyleVariant, Link, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { HomeGuidedSearch } from './HomeGuidedSearch';

export const MobileHomeGuidedSearchA = () => {
  const intl = useIntl();

  return (
    <HomeGuidedSearch
      TriggerComponent={({ showGuidedSearch }) => {
        return (
          <div className="relative p-4 text-center bg-sand-30">
            <Text variant={ETextStyleVariant.SmallRegular}>
              {intl.formatMessage({
                defaultMessage: 'Looking for help finding your ideal ride?',
                id: 'j9BVQJ',
              })}
            </Text>

            <Link
              variant={ELinkVariant.Underline}
              textVariant={ETextStyleVariant.MediumBold}
              label={intl.formatMessage({
                defaultMessage: `Begin Outdoorsy's guided search`,
                id: 'Uv33Sf',
              })}
              onClick={showGuidedSearch}
            />
          </div>
        );
      }}
    />
  );
};
