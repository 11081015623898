import {
  ELinkVariant,
  ETagVariant,
  ETextStyleVariant,
  Link,
  Tag,
  Text,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { HomeGuidedSearch } from './HomeGuidedSearch';

export const DesktopHomeGuidedSearchA = () => {
  const intl = useIntl();

  return (
    <HomeGuidedSearch
      TriggerComponent={({ showGuidedSearch }) => {
        return (
          <div className="flex justify-center w-full max-w-lg">
            <div className="flex items-center px-4 py-2 text-white rounded-b-lg bg-primary-800 gap-2">
              <Tag
                variant={ETagVariant.Canvas}
                label={intl.formatMessage({ defaultMessage: 'New', id: 'bW7B87' })}
              />

              <Text variant={ETextStyleVariant.MediumRegular}>
                {intl.formatMessage({
                  defaultMessage: 'Need help finding the perfect rig?',
                  id: 'y/O8+x',
                })}
              </Text>

              <Link
                onDark
                variant={ELinkVariant.Underline}
                textVariant={ETextStyleVariant.MediumBold}
                label={intl.formatMessage({
                  defaultMessage: 'Try our guided search',
                  id: 'She5wi',
                })}
                onClick={showGuidedSearch}
              />
            </div>
          </div>
        );
      }}
    />
  );
};
